/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/themes/saga-green/theme.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";

@font-face {
    font-family: 'Shippori Mincho B1';
    src: url('assets/fonts/ShipporiMinchoB1/ShipporiMinchoB1-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

.heading-font {
    font-family: 'Shippori Mincho B1';
}

.body-font {
    font-family: 'Open Sans';

}

html, body {
    font-family: 'Open Sans';
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 15px;
}